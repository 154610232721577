import {
	CONTEXT_PATH,
	SPACE_SETTINGS_FORGE_APPS,
	SPACE_SETTINGS_ARCHIVE_SPACE,
	SPACE_SETTINGS_EDITOR_CONVERSION,
	SPACE_SETTINGS_DATA_CLASSIFICATION,
	SPACE_SETTINGS_CONTENT_STATE,
	SPACE_SETTINGS_RELATED_PAGES,
	SPACE_SETTINGS_LANDING_PAGE,
	SPACE_SETTINGS_AUTOMATION,
	SPACE_SETTINGS_EDIT_FEATURES,
	SPACE_ARCHIVED_PAGES,
	SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE,
	SPACE_PERMISSIONS_SETTINGS,
	ADMIN_COMPANY_HUB_PERMISSIONS,
	ADMIN_CENTER_SPACE,
	SPACE_MEMBERS,
	SPACE_ANALYTICS,
	SPACE_ANALYTICS_PLUGIN,
	SPACE_BULK_TOOLS,
} from '@confluence/named-routes';
import type { RoutesContextType } from '@confluence/route-manager';
import type { ForgeUISpaceSettingsExtensionType } from '@confluence/forge-ui';
import { getAppId } from '@confluence/forge-ui';

import type {
	SpaceSettingsWebItemSectionsFragment,
	SpaceSettingsWebItemSectionsFragment_webItemSections,
	SpaceSettingsWebItemSectionsFragment_webItemSections_items,
} from '../web-item-sections/__types__/SpaceSettingsWebItemSectionsFragment';

/*
 * This file is for any webItem-related logic shared across sub folders in this package.
 * For example, both sidebar and navigation components need to retrieve the same data from
 * the webItem endpoint to decide which one is "selected" based on the current route.
 */

export const SPACE_TOOLS_WEBITEM_LOCATION = 'system.space.tools';
export const FORGE_SPACE_SETTINGS_NAVIGATION_PARENT_WEBITEM = '-system.space.tools-integrations';
export const AUTOMATION_WEBITEM_KEY =
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.addons.automation.confluence__confluence-space-automation-rules';
export const AUTOMATION_WEBITEM_SECTION_ID_V1 =
	'-system.space.tools-com.atlassian.addons.automation.confluence__space-automation';
export const AUTOMATION_WEBITEM_SECTION_ID_V2 = 'space-automation-section';

const PLUGIN_PARTIAL_PATH = '/wiki/plugins/';

export enum KeysOfWebItemsWithExtraUrls {
	SPACE_DETAILS_WEBITEM_COMPLETE_KEY = 'confluence.sections.space.tools.v2:spacedetails',
	EDIT_FEATURES_WEBITEM_COMPLETE_KEY = 'confluence.sections.space.tools.v2:editfeatures',
	GENERAL_PERMISSIONS_WEBITEM_COMPLETE_KEY = 'confluence.sections.space.tools.v2:spacepermissions',
	ADDON_PERMISSIONS_WEBITEM_COMPLETE_KEY = 'confluence.sections.space.tools.v2:addonspacepermissions',
	TRASH_WEBITEM_COMPLETE_KEY = 'confluence.sections.space.tools.v2:trash',
	EXPORT_SPACE_WEBITEM_COMPLETE_KEY = 'confluence.sections.space.tools.v2:export',
	PDF_EXPORT_WEBITEM_COMPLETE_KEY = 'com.atlassian.confluence.extra.flyingpdf:configpdf',
	APPLICATION_LINKS_WEBITEM_COMPLETE_KEY = 'com.atlassian.applinks.applinks-plugin:applinks-configure-entity-links-2',
}

// This would ideally be an enum as well, but TS doesn't allow string enums to have computed members.
// Using template literal due to CONTEXT_PATH in the string values makes these "computed".
// Open issue for TS to allow this: https://github.com/microsoft/TypeScript/issues/40793
// This const exists just to make the map below more readable and clear, and is exported for testing purposes
export const SpaceSettingsUrlsNotInWebItems = {
	EDIT_SPACE_DETAILS: `${CONTEXT_PATH}/spaces/editspace.action`,
	EDIT_SPACE_LABELS: `${CONTEXT_PATH}/spaces/editspacelabels.action`,
	EDIT_SPACE_PERMISSIONS: `${CONTEXT_PATH}/spaces/editspacepermissions.action`,
	EDIT_ADDON_PERMISSIONS: `${CONTEXT_PATH}/spaces/editaddonpermissions.action`,
	RESTORE_TRASH_PAGE: `${CONTEXT_PATH}/pages/restoretrashitem.action`,
	PURGE_TRASH_PAGE: `${CONTEXT_PATH}/pages/purgetrashitem.action`,
	PURGE_ALL_TRASH_PAGE: `${CONTEXT_PATH}/pages/emptytrash.action`,
	EXPORT_SPACE_HTML: `${CONTEXT_PATH}/spaces/exportspacehtml.action`,
	EXPORT_SPACE_XML: `${CONTEXT_PATH}/spaces/exportspacexml.action`,
	EXPORT_SPACE_CSV: `${CONTEXT_PATH}/spaces/exportspacecsv.action`,
	EXPORT_SPACE_PDF: `${CONTEXT_PATH}/spaces/flyingpdf/flyingpdf.action`,
	EDIT_PDF_EXPORT: `${CONTEXT_PATH}/spaces/flyingpdf/editpdfconfig.action`,
	APPLICATION_LINKS: `${CONTEXT_PATH}/spaces/listentitylinks.action`,
};

export const LEGACY_URL_TO_WEBITEM_MAPPING = {
	// Edit space details
	[SpaceSettingsUrlsNotInWebItems.EDIT_SPACE_DETAILS]:
		KeysOfWebItemsWithExtraUrls.SPACE_DETAILS_WEBITEM_COMPLETE_KEY,
	[SpaceSettingsUrlsNotInWebItems.EDIT_SPACE_LABELS]:
		KeysOfWebItemsWithExtraUrls.SPACE_DETAILS_WEBITEM_COMPLETE_KEY,
	// Edit permissions
	[SpaceSettingsUrlsNotInWebItems.EDIT_SPACE_PERMISSIONS]:
		KeysOfWebItemsWithExtraUrls.GENERAL_PERMISSIONS_WEBITEM_COMPLETE_KEY,
	[SpaceSettingsUrlsNotInWebItems.EDIT_ADDON_PERMISSIONS]:
		KeysOfWebItemsWithExtraUrls.ADDON_PERMISSIONS_WEBITEM_COMPLETE_KEY,
	// Trash actions
	[SpaceSettingsUrlsNotInWebItems.RESTORE_TRASH_PAGE]:
		KeysOfWebItemsWithExtraUrls.TRASH_WEBITEM_COMPLETE_KEY,
	[SpaceSettingsUrlsNotInWebItems.PURGE_TRASH_PAGE]:
		KeysOfWebItemsWithExtraUrls.TRASH_WEBITEM_COMPLETE_KEY,
	[SpaceSettingsUrlsNotInWebItems.PURGE_ALL_TRASH_PAGE]:
		KeysOfWebItemsWithExtraUrls.TRASH_WEBITEM_COMPLETE_KEY,
	// Export space legacy pages
	[SpaceSettingsUrlsNotInWebItems.EXPORT_SPACE_CSV]:
		KeysOfWebItemsWithExtraUrls.EXPORT_SPACE_WEBITEM_COMPLETE_KEY,
	[SpaceSettingsUrlsNotInWebItems.EXPORT_SPACE_HTML]:
		KeysOfWebItemsWithExtraUrls.EXPORT_SPACE_WEBITEM_COMPLETE_KEY,
	[SpaceSettingsUrlsNotInWebItems.EXPORT_SPACE_XML]:
		KeysOfWebItemsWithExtraUrls.EXPORT_SPACE_WEBITEM_COMPLETE_KEY,
	[SpaceSettingsUrlsNotInWebItems.EXPORT_SPACE_PDF]:
		KeysOfWebItemsWithExtraUrls.EXPORT_SPACE_WEBITEM_COMPLETE_KEY,
	// Edit PDF settings in Look and Feel
	[SpaceSettingsUrlsNotInWebItems.EDIT_PDF_EXPORT]:
		KeysOfWebItemsWithExtraUrls.PDF_EXPORT_WEBITEM_COMPLETE_KEY,
};

const SPA_SETTINGS_ROUTES = [
	SPACE_SETTINGS_ARCHIVE_SPACE.name,
	SPACE_SETTINGS_EDIT_FEATURES.name,
	SPACE_SETTINGS_EDITOR_CONVERSION.name,
	SPACE_SETTINGS_DATA_CLASSIFICATION.name,
	SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE.name,
	SPACE_SETTINGS_LANDING_PAGE.name,
	SPACE_SETTINGS_CONTENT_STATE.name,
	SPACE_SETTINGS_RELATED_PAGES.name,
	SPACE_SETTINGS_AUTOMATION.name,
	ADMIN_CENTER_SPACE.name,
];

const SPA_SETTINGS_ROUTES_NAV4 = [
	SPACE_SETTINGS_ARCHIVE_SPACE.name,
	SPACE_SETTINGS_EDIT_FEATURES.name,
	SPACE_SETTINGS_EDITOR_CONVERSION.name,
	SPACE_SETTINGS_DATA_CLASSIFICATION.name,
	SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE.name,
	SPACE_SETTINGS_LANDING_PAGE.name,
	SPACE_SETTINGS_CONTENT_STATE.name,
	SPACE_SETTINGS_RELATED_PAGES.name,
	SPACE_SETTINGS_AUTOMATION.name,
	SPACE_SETTINGS_FORGE_APPS.name,
	ADMIN_CENTER_SPACE.name,
	SPACE_ANALYTICS.name,
	SPACE_ANALYTICS_PLUGIN.name,
	SPACE_BULK_TOOLS.name,
	SPACE_ARCHIVED_PAGES.name,
	ADMIN_COMPANY_HUB_PERMISSIONS.name,
	SPACE_PERMISSIONS_SETTINGS.name,
	SPACE_MEMBERS.name,
];

export const isForgeAppRoute = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	const route = matchRoute(location?.pathname || '');
	return route?.name === SPACE_SETTINGS_FORGE_APPS.name;
};

export const isSpaceMembersRoute = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	const route = matchRoute(location?.pathname || '');
	return route?.name === SPACE_MEMBERS.name;
};

export const isSPASettingsRoute = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
	isSideNavRefreshAutomationEnabled?: boolean,
): boolean => {
	const route = matchRoute(location?.pathname || '');
	const SPASettingsRoutes = SPA_SETTINGS_ROUTES;
	SPASettingsRoutes.push(SPACE_ARCHIVED_PAGES.name);

	const automationIndex = SPASettingsRoutes.indexOf(SPACE_SETTINGS_AUTOMATION.name);
	if (isSideNavRefreshAutomationEnabled && automationIndex > -1) {
		// remove automation from SPA settings routes
		SPASettingsRoutes.splice(automationIndex, 1);
	}

	return SPASettingsRoutes.includes(route?.name ?? '');
};

export const isLandingPage = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	const route = matchRoute(location?.pathname || '');
	return route?.name === SPACE_SETTINGS_LANDING_PAGE.name;
};

export const isAutomationPage = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	const route = matchRoute(location?.pathname || '');
	return route?.name === SPACE_SETTINGS_AUTOMATION.name;
};

const isSPASettingsNav4Route = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	const route = matchRoute(location?.pathname || '');

	return SPA_SETTINGS_ROUTES_NAV4.includes(route?.name ?? '');
};

export const isForgeAppMatchingRoute = (
	app: ForgeUISpaceSettingsExtensionType,
	appId: string | undefined,
	appEnvId: string | undefined,
	forgeManifestRoute: string | undefined,
): boolean => {
	return (
		appId === getAppId(app) &&
		appEnvId === app.environmentId &&
		// Route is required param, but it was not before. Checking only if it is defined
		(app.properties?.route ? forgeManifestRoute === app.properties?.route : true)
	);
};

export const isSpacePermissionsRoute = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	const route = matchRoute(location?.pathname || '');
	return route?.name === SPACE_PERMISSIONS_SETTINGS.name;
};

export const isHubPermissionsRoute = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	const route = matchRoute(location?.pathname || '');
	return route?.name === ADMIN_COMPANY_HUB_PERMISSIONS.name;
};

export const isRouteInSpaceSettings = (
	matchRoute: RoutesContextType['matchRoute'],
	location?: RoutesContextType['location'],
): boolean => {
	return isSPASettingsNav4Route(matchRoute, location);
};

type SelectedNavigationWebItems = {
	innerNavigationWebItem?: SpaceSettingsWebItemSectionsFragment_webItemSections_items | null;
	outerNavigationWebItem?: SpaceSettingsWebItemSectionsFragment_webItemSections | null;
};

export const useSelectedNavigationWebItems = ({
	matchRoute,
	location,
	webItemSections,
	skip,
}: {
	matchRoute: RoutesContextType['matchRoute'];
	location?: RoutesContextType['location'];
	webItemSections?: SpaceSettingsWebItemSectionsFragment['webItemSections'];
	skip?: boolean;
}): SelectedNavigationWebItems => {
	if (skip) {
		return {
			innerNavigationWebItem: undefined,
			outerNavigationWebItem: undefined,
		};
	}

	const isForgeAppPage = isForgeAppRoute(matchRoute, location);

	let innerNavigationWebItem;
	const outerNavigationWebItem = webItemSections?.find((webItemSection) => {
		const items = webItemSection?.items;

		// We hardcode selection of the correct tab if this is a Forge app
		if (isForgeAppPage && items?.length) {
			return webItemSection?.id === FORGE_SPACE_SETTINGS_NAVIGATION_PARENT_WEBITEM;
		}

		if (!location?.pathname) return false;

		return items?.some((item, itemIndex) => {
			if (item?.url?.includes(location.pathname)) {
				if (location.pathname.startsWith(PLUGIN_PARTIAL_PATH)) {
					// plugins have a different rule: pathname might be identical across plugins, and query parameters
					// may be the only distinguishing factor. So for plugins, check the *complete* URL (pathname + params)
					if (`${location.pathname}${location.search}`.includes(item?.url)) {
						innerNavigationWebItem = item;
						return true;
					}
					// this is a plugin, but we didn't fully match the path
					return false;
				}

				// Pathname may not be strict enough, try pathname+search first:
				const pathnameWithSearch = `${location.pathname}${location.search}`;
				for (let i = itemIndex; i < items.length; i++) {
					const url = items[i]?.url;
					if (url && pathnameWithSearch.includes(url)) {
						innerNavigationWebItem = items[i];
						return true;
					}
				}

				// NOT a plugin; URL is already matched, so we have our selected tab
				innerNavigationWebItem = item;
				return true;
			}

			// We could be on an "edit" legacy page which does NOT appear in webItems but is associated
			// with a specific space settings page nonetheless. For example, "Edit space permissions" page
			// does not appear in webItems, but it is a link from the existing "General" space permissions tab
			// and the navigation should point to that webItem. We must maintain a hardcoded mapping of these.
			if (LEGACY_URL_TO_WEBITEM_MAPPING.hasOwnProperty(location.pathname)) {
				// BUGFIX: Which was caused by not considering the "-personal" suffixed "spacedetails" completeKey in "LEGACY_URL_TO_WEBITEM_MAPPING".
				const finalCompleteKey = item?.completeKey?.endsWith('-personal')
					? item?.completeKey?.replace('-personal', '')
					: item?.completeKey;
				// check the current webItem completeKey
				if (finalCompleteKey === LEGACY_URL_TO_WEBITEM_MAPPING[location.pathname]) {
					innerNavigationWebItem = item;
					return true;
				}
			}

			return false;
		});
	});

	return { innerNavigationWebItem, outerNavigationWebItem };
};
